<template>
    <div class='container '>
        <div class="flex sgal"> 
            <div class="item" v-for="(item,i) in sgal" :key="i" :title="item.title">
                <div class="img2"  @click="openpage('/fwdetail',item.product_id)">
                     <div class="before">
                            <img class="swiper-img" :src="item.image" alt="暂无图片" />   
                    </div>
                   <!--   <div class="before">
                        <img class="swiper-img" :src="item.picbefore" alt="暂无图片" />
                        <div class="tip">施工前</div>
                    </div>
                    <div class="after">
                        <img class="swiper-img" :src="item.picafter" alt="暂无图片" />
                        <div class="tip">施工后</div>
                    </div> -->
                </div>
                <div class="btn2 ">
                    <div class="name"   @click="openpage('/fwdetail',item.product_id)">{{item.title}}</div>
                    <div class=" zixun ">
                       
                        <div class="btn btn-primary-sm" @click="openpage('fbxq',item.product_id,true)">发布需求</div>
                         <div class="btn btn-warning-sm" @click="getkefu()">咨询管家</div>
                    </div>
                </div>
            </div>
      
        </div>
    </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
export default {
    components: {},
    data () {
        // 这里存放数据
        return {
             sgal: [
                // {
                // picbefore: require("@img/linshi/1.png"),
                // picafter: require("@img/linshi/2.png"),
                // name: "工业化自动化和机器人",
                // },
            ],
        }
    },
    // 监听属性 类似于data概念
    computed: {
         ...mapState(["isLogin", "userInfo"]),
    },
    mounted(){
        //  获取施工案例
        this.$http.case_home().then(res=>{
            this.sgal=res.data.list;
        })
    },
    methods: {
          ...mapMutations([
            "setLogin",
            "setUserInfo",
            "setLoginDialog",
            "setRegistDialog",
            ]),
        getkefu() {
            if (this.isLogin != 1) {
                this.setLoginDialog(true);
            
                }else{
                this.$store.dispatch("setkefu");
                }
        },
        openpage(path,id, islogin){
             if (islogin) {
                if (this.isLogin != 1) {
                this.setLoginDialog(true);
                // this.$message.error("请先登录");
                return;
                }
            }
            this.$router.push({
                path,
                query: {
                id,
                },
            })
        }
    }
}
</script>
<style scoped lang="scss">
@import "~@/styles/mixin.scss";
.container{
    padding: 30px 20px;
    border-radius: 5px;
     background: #fff;
     margin-bottom: 20px;
}
.sgal {
    width: 100%;
    flex-wrap: wrap;
   .btn2{
       padding: 20px 0;
       font-size: 20px;
       margin-bottom: 10px;
        
       .name{
           line-height: 1.5;
           margin-bottom: 15px;
        
            @include single-line-ellipsis; 
         /*   width:50%;
          */
       }
   }
    .zixun{
        overflow: hidden;
        .btn{
            margin-left:30px ;
            float: right;
        }
    }
}
     .item{
        // width: calc(50% -  10px);
          width: calc(33% -  10px);
        cursor: pointer;
     }
      .item:nth-child(3n+1),.item:nth-child(3n+2){
        margin-right: 20px;
    }
   /*  .item:nth-child(2n+1){
        margin-right: 20px;
    } */
  .img2 {
    display: flex;
    border-radius: 10px;
    overflow: hidden;
    .before,
    .after {
      flex: 1;
      width: 190px;
      height: 230px;
      position: relative;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      .tip {
        position: absolute;
        top: 0;
        left: 20px;
        font-size: 20px;
        background-color: rgba(0, 0, 0, 0.5);
        color: #fff;
        padding: 10px 9px 15px;
        border-radius: 0 0 30px 30px;
        overflow: hidden;
        writing-mode: vertical-lr;
      }
    }
  }
 
</style>
